import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import { getEditorSdkSrc } from './editor-sdk-actions';
import { handleListMigration } from './list-migration/list-migration';
import { migrate as migrateServicePage } from './service-page-migration/service-page-migration';
import { handlePagesPanelMigration } from './migrate-actions';
import { MigrationFailReason, PageId, FedopsInterations } from '../constants';
import { migrate as migrateCalendarPage } from './calendar-page-migration/calendar-page-migration';
import { migrate as migrateFormPage } from './form-page-migration/form-page-migration';
import { closeBar, openBar, setBarStep } from './progressbar-utils';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptApi } from '../api/api';
import { migrateAction, MigrateAction } from '../migration';
import { Translations } from './translations';

const BOOKINGS_URL_PREFIX = 'https://bookings.wixapps.net';
const BOOKINGS_EDITOR_MODALS = 'bookings-editor-modals';

const handleListPageMigrationProposalResult = async (
  migrationProposalResult,
  editorSdk,
  wixEditorSdkSrc,
  locale,
  appToken,
  instance,
  translation,
  allSitePages,
) => {
  if (
    migrationProposalResult.status &&
    migrationProposalResult.status === 'confirmed'
  ) {
    try {
      await handleListMigration(editorSdk, appToken, translation);
      await handlePagesPanelMigration(editorSdk, appToken, allSitePages);
      await editorSdk.document.save();
      await openMigrationSucceedModal(
        editorSdk,
        wixEditorSdkSrc,
        locale,
        instance,
      );
    } catch (e) {
      console.log('failed to migrate bookings', e);
      await openMigrationFailedModal(
        editorSdk,
        wixEditorSdkSrc,
        locale,
        instance,
      );
    }
  }
};

const openModal = async ({
  editorSdk,
  wixEditorSdkSrc,
  locale,
  modal,
  width,
  height,
  instance,
  dynamicContent = [],
  reason = undefined,
  referral = undefined,
}) => {
  const url = `${BOOKINGS_URL_PREFIX}/${BOOKINGS_EDITOR_MODALS}?wixEditorSdkSrc=${wixEditorSdkSrc}&modal=${modal}&locale=${locale}&instance=${instance}${
    dynamicContent
      ? dynamicContent.reduce(
          (accumulate, content) => accumulate + `&dynamicContent=${content}`,
          '',
        )
      : ''
  }${reason ? `&reason=${reason}` : ''}${'&theme=facelift'}${
    referral ? `&referral=${referral}` : ''
  }`;
  return editorSdk.editor.openModalPanel('token', {
    url,
    shouldHideHeader: true,
    width,
    height,
    centered: true,
  });
};

const openListPageMigrationProposalModal = (
  editorSdk,
  wixEditorSdkSrc,
  locale,
  instance,
) =>
  openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'split_migration_proposal',
    width: 504,
    height: 486,
  });

export const openEcomMigrationFailedModal = async (
  editorSdk: EditorSDK,
  editorScriptApi: EditorScriptApi,
  locale: any,
  instance: any,
) => {
  const wixEditorSdkSrc = getEditorSdkSrc(editorSdk);
  await editorScriptApi.sleep(1000);
  openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'ecom_migration_failure',
    width: 600,
    height: 270,
  });
};

export const openEcomMigrationSuccessModal = async (
  editorSdk: EditorSDK,
  editorScriptApi: EditorScriptApi,
  locale: any,
  instance: any,
) => {
  const wixEditorSdkSrc = getEditorSdkSrc(editorSdk);
  await editorScriptApi.sleep(1000);
  openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'ecom_migration_success',
    width: 600,
    height: 245,
  });
};

export const openEcomMigrationProposalModal = async (
  editorSdk: EditorSDK,
  editorScriptApi: EditorScriptApi,
  locale: any,
  fedopsLogger: IFedOpsLogger,
  instance: any,
  editorTranslation: Translations,
  migrationActionInput: MigrateAction
) => {
  const wixEditorSdkSrc = getEditorSdkSrc(editorSdk);
  await editorScriptApi.sleep(1000);
  const modalResult = await openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'ecom_migration_proposal',
    width: 600,
    height: 580,
  });

  if (modalResult?.status === 'confirmed') {
    try {
      fedopsLogger.interactionStarted(FedopsInterations.EcomSelfMigration);
      await migrateAction(migrationActionInput);
      await editorScriptApi.selfMigrationDone();
      fedopsLogger.interactionEnded(FedopsInterations.EcomSelfMigration);
      openEcomMigrationSuccessModal(editorSdk, editorScriptApi, locale, instance);
    } catch (e) {
      openEcomMigrationFailedModal(editorSdk, editorScriptApi, locale, instance);
      await editorScriptApi.unmarkMigratedSiteEcomCompatible();
      console.log(`${
        e?.message ? e.message : JSON.stringify(e)
      } - errorCode: LAZY_MIGRATION_FAILED`);
    }
  }
};

export const openEcomHistoryBlockModal = async (
  editorSdk: EditorSDK,
  editorScriptApi: EditorScriptApi,
  locale: any,
  instance: any,
) => {
  const wixEditorSdkSrc = getEditorSdkSrc(editorSdk);
  await editorScriptApi.sleep(1000);
  openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'ecom_history_block',
    width: 600,
    height: 294,
  });
};

export const openCartSuccessfullyInstalledModal = async (
  editorSdk: EditorSDK,
  editorScriptApi: EditorScriptApi,
  locale: any,
  instance: any,
) => {
  const wixEditorSdkSrc = getEditorSdkSrc(editorSdk);
  await editorScriptApi.sleep(1000);
  openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'ecom-cart-installation-status',
    width: 600,
    height: 246,
  });
};

const openMigrationFailedModal = (
  editorSdk,
  wixEditorSdkSrc,
  locale,
  instance,
) =>
  openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'split_migration_failure',
    width: 564,
    height: 284,
  });

const openMigrationSucceedModal = (
  editorSdk,
  wixEditorSdkSrc,
  locale,
  instance,
) =>
  openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'split_migration_success',
    width: 468,
    height: 486,
  });

export const proposeListMigration = async (
  editorSdk,
  appToken,
  locale,
  instance,
  translation,
  allSitePages,
) => {
  const wixEditorSdkSrc = getEditorSdkSrc(editorSdk);
  const migrationProposalResult = await openListPageMigrationProposalModal(
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
  );
  await handleListPageMigrationProposalResult(
    migrationProposalResult,
    editorSdk,
    wixEditorSdkSrc,
    locale,
    appToken,
    instance,
    translation,
    allSitePages,
  );
};

const openPagesMigrationProposalModal = ({
  sdk,
  wixEditorSdkSrc,
  locale,
  instance,
  pagesId,
  referral,
}) =>
  openModal({
    editorSdk: sdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'bookings_pages_migration_proposal',
    dynamicContent: pagesId,
    width: 600,
    height: 643,
    referral,
  });

const openPagesMigrationSuccessModal = (
  editorSdk,
  wixEditorSdkSrc,
  locale,
  instance,
) =>
  openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'bookings_pages_migration_success',
    width: 600,
    height: 246,
  });

const openPagesMigrationFailedModal = async (
  editorSdk,
  wixEditorSdkSrc,
  locale,
  instance,
  reason: MigrationFailReason,
) =>
  openModal({
    editorSdk,
    wixEditorSdkSrc,
    locale,
    instance,
    modal: 'bookings_pages_migration_failure',
    width: 600,
    height: 294,
    reason,
  });

export const migrator = async ({
  sdk,
  appToken,
  locale,
  instance,
  editorTranslation,
  allSitePages,
  editorScriptApi,
  referral,
}) => {
  const installableBookingsPages = [
    {
      totalSteps: 3,
      stepTranslation: 'service-page-migration.progress-bar.step',
      pageId: PageId.BOOKINGS_SERVICE_PAGE,
      async migrate(onNextStep) {
        await migrateServicePage(
          sdk,
          appToken,
          editorTranslation,
          onNextStep,
          editorScriptApi,
        );
      },
    },
    {
      totalSteps: 3,
      stepTranslation: 'calendar-page-migration.progress-bar.step',
      pageId: PageId.BOOKINGS_CALENDAR_PAGE,
      async migrate(onNextStep) {
        await migrateCalendarPage(
          sdk,
          appToken,
          instance,
          editorTranslation,
          onNextStep,
        );
      },
    },
    {
      totalSteps: 3,
      stepTranslation: 'form-page-migration.progress-bar.step',
      pageId: PageId.BOOKINGS_FORM_PAGE,
      async migrate(onNextStep) {
        await migrateFormPage(
          sdk,
          appToken,
          instance,
          editorTranslation,
          onNextStep,
        );
      },
    },
  ];

  const shouldMigrate = (pageId) =>
    !allSitePages.find((page) => page.tpaPageId === pageId);

  const pagesToInstall = installableBookingsPages.filter(({ pageId }) =>
    shouldMigrate(pageId),
  );

  if (pagesToInstall.length) {
    const wixEditorSdkSrc = getEditorSdkSrc(sdk);
    const pagesId = pagesToInstall.map(({ pageId }) => pageId);
    const allSteps = pagesToInstall
      .map(({ totalSteps }) => totalSteps)
      .reduce((totalPageSteps, totalSteps) => totalPageSteps + totalSteps, 0);
    const migrationProposalResult = await openPagesMigrationProposalModal({
      sdk,
      wixEditorSdkSrc,
      locale,
      instance,
      pagesId,
      referral,
    });

    if (migrationProposalResult?.status === 'confirmed') {
      await openBar(
        sdk,
        appToken,
        editorTranslation,
        'bookings-migration.progress-bar.title',
        allSteps,
      );
      const onNextStep = async (stepTranslation) => {
        currentStep++;
        setBarStep(
          sdk,
          appToken,
          editorTranslation,
          stepTranslation,
          currentStep,
        );
      };
      let currentStep = 0;
      try {
        for (const pageToInstall of pagesToInstall) {
          await pageToInstall.migrate((nextStep) =>
            onNextStep(`${pageToInstall.stepTranslation}-${nextStep}`),
          );
        }
        await sdk.document.save();
        const newAllSitePages = await sdk.pages.data.getAll();
        await handlePagesPanelMigration(sdk, appToken, newAllSitePages);
        closeBar(sdk, appToken);
        await openPagesMigrationSuccessModal(
          sdk,
          wixEditorSdkSrc,
          locale,
          instance,
        );
      } catch (e) {
        console.log('failed to migrate bookings pages', e);
        closeBar(sdk, appToken);
        await openPagesMigrationFailedModal(
          sdk,
          wixEditorSdkSrc,
          locale,
          instance,
          e,
        );
      }
    }
  }
};
